<template>
  <v-container>
    <v-card
      class="align-content-center card-register"
      max-width="100%"
      heigth="431px"
      outlined
      single-line
    >
      <v-row>
        <v-col md="8" sm="8" class="offset-md-2 py-16">
          <v-list-item>
            <v-list-item-content>
              <div class="previous-text">
                {{ $t('register.secundary_title_whitout_sowing') }}
              </div>
              <v-list-item-title class="title-register mb-1">
                {{ $t('register.register_sowing') }}
              </v-list-item-title>
              <v-list-item-subtitle class="wrap-text">
                <p class="description-register">
                  {{ $t('register.secundary_text_whitout_sowing') }}
                </p>
              </v-list-item-subtitle>
            </v-list-item-content>
            <div v-if="$vuetify.breakpoint.smAndUp">
              <v-img src="img/farmer-sowing.webp" max-width="160px" />
            </div>
          </v-list-item>

          <v-row no-gutters>
            <v-col>
              <v-card-actions>
                <v-btn
                  outlined
                  text
                  class="white--text text-none button-register"
                  @click="openPlantingForm"
                >
                  {{ $t('register.sowing') }}
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <modal-semeadura
      v-if="openPlantingModal"
      :dialog="openPlantingModal"
      :semeadura="plantingSelected"
      @close="closePlantingForm"
      @reload-sowing-list="reloadSowingList"
    />
  </v-container>
</template>

<script>
import ModalSemeadura from '@/pages/semeaduras/ModalSemeadura.vue'

export default {
  name: 'WithoutSowing',

  components: { ModalSemeadura },

  data() {
    return {
      openPlantingModal: false,
    }
  },

  props: {
    plantingSelected: {
      type: Object,
      default: null,
    },
  },

  methods: {
    openPlantingForm() {
      this.openPlantingModal = true
    },
    closePlantingForm() {
      this.openPlantingModal = false
    },
    reloadSowingList() {
      this.$emit('reload-sowing-list')
    },
  },
}
</script>

<style>
.card-register {
  border-color: white;
}
.previous-text {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #51594f;
}

.button-register {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  background-color: #39af49;
  border-color: #39af49;
}

.title-register {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  color: black;
  white-space: initial !important;
}

.description-register {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #51594f;
}

.wrap-text {
  text-overflow: initial !important;
  white-space: initial !important;
}
</style>
