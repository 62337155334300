<template>
  <div>
    <v-alert
      class="alert-style mt-4"
      prominent
      text-color="#000"
      color="#D8F2DC"
    >
      <v-row align="center" no-gutters>
        <font-awesome-icon class="mr-5" color="#1D5825" icon="seedling" />
        <!-- eslint-disable vue/no-v-html -->
        <v-col
          class="grow"
          v-html="$t('sowing.message_sowing_heady_to_harvest')"
        ></v-col>
        <!--eslint-enable-->
        <v-col class="shrink ml-2" v-if="hasPermission($p.harvest.create)">
          <v-btn
            outlined
            text
            class="white--text text-none button-banner"
            @click="openModalHarvest"
          >
            {{ $t('harvest.register_harvest') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import PermissionMixin from '@/components/Permission/PermissionMixin'

export default {
  mixins: [PermissionMixin],

  methods: {
    openModalHarvest() {
      this.$emit('open-modal-harvest')
    },
  },
}
</script>

<style scoped>
.alert-style {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  border-radius: 8px !important;
}
.button-banner {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  background-color: #39af49;
  letter-spacing: 0.9px;
  border-color: #39af49;
}
</style>
