<template>
  <div class="mt-1">
    <div v-for="efficacy in efficaciesWithoutDiseases" :key="efficacy.alvo_id">
      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-icon
            class="severity-cloud-label"
            left
            small
            dark
            :color="severidadeColor(efficacy.severidade_id)"
            v-bind="attrs"
            v-on="on"
            >mdi-weather-rainy</v-icon
          >
        </template>
        <span>
          {{ severityLabel(efficacy.severidade_id) }}
        </span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import CropDiseasesMixin from '@/components/Spraying/CropDiseasesMixin'

const HIGH = 1
const HIGH_MEDIUM = 5
const MEDIUM = 2
const MEDIUM_LOW = 4
const LOW = 3

export default {
  mixins: [CropDiseasesMixin],
  props: {
    efficacies: {
      type: Array,
      required: true,
    },
  },
  computed: {
    efficaciesWithoutDiseases() {
      return this.efficacies.filter(
        (efficacy) => !this.ignoredDiseases?.includes(efficacy.alvo_id)
      )
    },
  },
  methods: {
    severityLabel(severityId) {
      if ([HIGH].includes(severityId)) {
        return this.$t('severidade.alta')
      }
      if ([HIGH_MEDIUM].includes(severityId)) {
        return this.$t('severidade.media_alta')
      }
      if ([MEDIUM].includes(severityId)) {
        return this.$t('severidade.media')
      }
      if ([MEDIUM_LOW].includes(severityId)) {
        return this.$t('severidade.media_baixa')
      }
      if ([LOW].includes(severityId)) {
        return this.$t('severidade.baixa')
      }
    },
    severidadeColor(severidade_id) {
      switch (severidade_id) {
        case HIGH:
          return 'red darken-1'
        case HIGH_MEDIUM:
          return 'red darken-1'
        case MEDIUM:
          return 'orange darken-1'
        case MEDIUM_LOW:
          return 'yellow darken-1'
        case LOW:
          return 'yellow darken-1'
        default:
          break
      }
    },
  },
}
</script>

<style scoped>
.severity-cloud-label {
  margin-top: 3px;
}
</style>
