<template>
  <div>
    <v-alert
      class="alert-style mt-4"
      prominent
      text-color="#000"
      color="#D8F2DC"
    >
      <v-row align="center" no-gutters>
        <font-awesome-icon class="mr-5" color="#1D5825" icon="seedling" />
        <v-col
          class="grow"
          v-html="
            $t('sowing.message_heady_to_harvest', [
              sowingsReadyToHarvest.length,
            ])
          "
        >
        </v-col>
        <v-col class="shrink ml-2" v-if="hasPermission($p.harvest.create)">
          <v-btn
            outlined
            text
            class="white--text text-none button-banner"
            @click="openModalMultiHarvest"
          >
            {{ $t('harvest.register_harvests') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <modal-multi-harvest
      :dialog="dialog"
      @close="closeModalMultiHarvest"
    ></modal-multi-harvest>
  </div>
</template>

<script>
import PermissionMixin from '@/components/Permission/PermissionMixin'
import ModalMultiHarvest from '@/pages/execucao/ModalMultiHarvest.vue'
import { logEvent, events } from '@/services/analytics'
import { mapGetters } from 'vuex'

export default {
  mixins: [PermissionMixin],

  data() {
    return {
      dialog: false,
    }
  },

  components: {
    ModalMultiHarvest,
  },

  computed: {
    ...mapGetters('semeaduras', ['sowingsReadyToHarvest']),
  },

  methods: {
    openModalMultiHarvest() {
      logEvent(events.harvests.clickedButton_registerMultipleHarvest)
      this.dialog = true
    },

    closeModalMultiHarvest() {
      this.dialog = false
    },
  },
}
</script>

<style scoped>
.alert-style {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  border-radius: 8px !important;
}
.button-banner {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  background-color: #39af49;
  letter-spacing: 0.9px;
  border-color: #39af49;
}
</style>
