<script>
import { logEvent, events } from '@/services/analytics'

export default {
  props: {
    farmId: { type: Number, required: true },
    startPeriod: { type: String, default: '' },
    endPeriod: { type: String, default: '' },
    isSoybean: { type: Boolean, default: false },
    isWheat: { type: Boolean, default: false },
  },
  computed: {
    enableReportButton() {
      return this.$toggle('season_report')
    },
  },
  methods: {
    clickButton() {
      logEvent(events.seasonReport.clicked)
    },
  },
}
</script>

<template>
  <div v-if="enableReportButton">
    <v-btn
      class="text-none report-button"
      large
      :href="`#/reports/season-report?id=${farmId}&isSoybean=${isSoybean}&isWheat=${isWheat}${
        startPeriod && endPeriod
          ? `&startPeriod=${startPeriod}&endPeriod=${endPeriod}`
          : ''
      }`"
      target="_blank"
      color="#FFF"
      @click="clickButton"
    >
      <div>
        {{ $t('report.report') }}
      </div>
      <font-awesome-icon class="pl-1 x-large" color="#39AF49" icon="download" />
    </v-btn>
  </div>
</template>

<style scoped>
::v-deep .v-text-field--outlined .v-label {
  top: 0px !important;
}

.report-button {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3f4e3c !important;
}
</style>
