<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    isMenuItem: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters('execucoes', ['nextSpraying']),
    ...mapGetters('integrations', ['isIntegrated']),

    showPrescriptionButton() {
      return this.isIntegrated && this.nextSpraying
    },
  },

  methods: {
    async exportPrescription() {
      try {
        const response = await this.$api.prescription.exportPrescription(
          this.nextSpraying?.id
        )

        const data = window.URL.createObjectURL(response.data)
        const link = document.createElement('a')
        link.href = data
        link.download = 'prescription.zip'
        link.click()
      } catch (e) {
        this.$root.$emit('notify', 'error', 'Erro ao exportar prescrição')
      }
    },
  },
}
</script>

<template>
  <div v-if="showPrescriptionButton">
    <v-list-item @click="exportPrescription" v-if="isMenuItem">
      <v-list-item-title class="qa-menu__export-prescription-btn"
        ><v-icon>mdi-file-download</v-icon> &nbsp;
        {{ $t('prescription_export') }}
      </v-list-item-title>
    </v-list-item>

    <v-btn
      class="qa-menu__export-prescription-btn"
      elevation="2"
      small
      @click="exportPrescription"
      v-else
    >
      <v-icon color="#39af49">mdi-file-download</v-icon> &nbsp;
      {{ $t('prescription_export_next') }}
    </v-btn>
  </div>
</template>

<style></style>
