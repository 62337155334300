<template>
  <v-slider
    v-model="rawValue"
    thumb-color="primary"
    min="0"
    :max="maxValue"
    :step="stepValue"
    append-icon="mdi-plus-circle-outline"
    prepend-icon="mdi-minus-circle-outline"
    class="ml-3 mr-2"
    @click:append="plus()"
    @click:prepend="minus()"
  />
</template>

<script>
import { convertToTargetUnit, convertToStandardUnit } from './converter'
import { currentLanguage, LANG } from '@/language'

export default {
  name: 'ConverterSlider',

  type: 'rainfall',

  data() {
    return {
      rawValue: null,
    }
  },

  props: {
    value: {
      type: [String, Number],
      default: null,
    },
  },

  watch: {
    value: {
      handler(newValue) {
        if (newValue == '' && this.rawValue == '') {
          return
        }
        const formatted = convertToTargetUnit(
          newValue,
          this.$options.type,
          false
        )
        if (formatted !== this.rawValue) {
          this.rawValue = formatted
        }
      },
      immediate: true,
    },
    rawValue: {
      handler(newRawValue, oldRawValue) {
        const unformattedValue = convertToStandardUnit(
          newRawValue,
          this.$options.type
        )
        const oldUnformattedValue = convertToStandardUnit(
          oldRawValue,
          this.$options.type
        )

        if (unformattedValue !== oldUnformattedValue) {
          this.$emit('input', unformattedValue)
          this.$emit('change', unformattedValue)
        }
      },
    },
  },

  computed: {
    maxValue() {
      return currentLanguage() == LANG.EN ? 2.75 : 70
    },
    stepValue() {
      return currentLanguage() == LANG.EN ? 0.1 : 1
    },
    convertedStepValue() {
      return currentLanguage() == LANG.EN ? 2.5 : 1
    },
  },

  methods: {
    minus() {
      this.$emit('minus', this.convertedStepValue)
    },
    plus() {
      this.$emit('plus', this.convertedStepValue)
    },
  },
}
</script>
